.title {
  font-family: Pluto Sans, SansSerif, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #191919;
}

.navItem {
  text-decoration: none;
  font-family: Pluto Sans, SansSerif, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #191919;
}

.hamburger {
  text-decoration: none;
  border: none;
  background: #ffffff;
}

.microsoft {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #80c342;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
}

.icon-link {
  text-decoration: none;
}

.small-margins {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hr-gel {
  border: none;
  height: 1px;
  color: #666;
  background-color: #cccccc;
}

.nav-menu {
  padding-right: "15px";
  cursor: "pointer";
}

.avatar {
  background-color: "#80C342";
}
