.background {
  background-color: #303e46;
  margin: -16px -16px 0 -16px;
  padding: 16px;
  overflow-x: hidden;
}

.whiteText {
  color: #fff;
}
