.footer {
  max-width: 1080px;
}
.footer-link {
  color: #abacae;
  text-decoration: none;
  border-bottom: 1px solid #80c342;
  padding-bottom: 3px;
}

.footer-link :active {
  color: #abacae;
}

.caption-color {
  color: #abacae;
}
