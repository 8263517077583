.heading {
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #666666;
}

.subHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #666666;
}
.max-width {
  max-width: 800px;
}

.greenQuote {
  background: rgba(128, 195, 66, 0.05);
  border: 1px solid #80c342;
  box-sizing: border-box;
  padding: 24px 16px;
}

.quote {
  padding-bottom: 85px;
}

.blackText {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}

.promptLine {
  border-style: solid;
  border-width: 1px;
  height: 2px;
  width: 80px;
  background-color: black;
  margin: 35px 0;
}

.promptLabel {
  padding-bottom: 20px;
}

.titleText {
  margin-bottom: 20px !important;
}

.marginT50 {
  margin-top: 50px !important;
}

.marginB50 {
  margin-bottom: 50px !important;
}

.marginT20DisplayBlock {
  margin-top: 20px !important;
  display: flex !important;
}
