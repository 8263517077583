.suicideWarning {
  background-color: #303e46;
  margin: 16px 0;
  padding: 16px;
}

.whiteText {
  color: #fff;
  font-size: 15px;
}

.blackText {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}

.hr-gel {
  border: none;
  height: 1px;
  color: #666;
  background-color: #cccccc;
}

.greenQuote {
  background: rgba(128, 195, 66, 0.05);
  border: 1px solid #80c342;
  box-sizing: border-box;
  padding: 24px 16px;
}

.quote {
  padding-bottom: 85px;
}
