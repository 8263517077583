.visually-hidden {
  appearance: none;
  display: none;
  height: 0;
  width: 0;
}

.rating-label:hover {
  cursor: pointer;
}

.visually-hidden + .rating-label {
  display: inline-block;
  width: 80px;
  padding: 0.5em 0;
  text-align: center;
  border: 1px solid #80c342;
  box-sizing: border-box;
  color: #555;
  background-color: #ffffff;
}

.visually-hidden:checked + .rating-label {
  background-color: #80c342;
  color: #000000;
}

.slider-description {
  font-size: 13px;
  color: #191919;
}

.hr-gel {
  border: none;
  height: 1px;
  color: #666;
  background-color: #cccccc;
}

.max-width {
  max-width: 800px;
}
