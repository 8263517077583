.label {
  color: #808080 !important;
  padding-right: 5px;
  padding-left: 5px;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  border: none;
  cursor: default;
  min-height: 40px;
  outline: none;
  padding: 0;
  font-size: 25px;
  color: white;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 20px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.125;
}
